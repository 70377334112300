import * as React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from './utils/GlobalStyles';
import './layout.css';
import Navbar from './Navbar/Navbar';
import Footer from './footer/footer';
import styled from 'styled-components';

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

const Main = styled.main`
  flex: 1 0 auto;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
